<template>
  <div class="bindId_box">
    <!-- <div class="tips">
      <p> {{$t('toast.bindIdTxt1')}}</p>
      <p>{{$t('toast.bindIdTxt2')}}</p>
    </div> -->
    <!-- <ul class="phone_login">
      <li class="phone_login_item">
        <input v-model="real_name"
               class="fl psw_code"
               type="text"
               :placeholder="$t('toast.namePlaceholder')" />
      </li>
      <li class="phone_login_item">
        <input v-model="identity_no"
               class="account_code"
               type="text"
               @blur="testID"
               :placeholder="$t('toast.IDPlaceholder')" />
      </li>

      <li class="login_submit">
        <span class="btn_item cancel"
              @click="cancel">{{$t('toast.comfirmBtn5')}}</span>
        <span class="btn_item confirm"
              @click="bindID">{{$t('toast.comfirmBtn2')}}</span>
      </li>
    </ul> -->
    <fcmRehister v-if="tipsFlag"
                 @closeTips="closeTips" />
  </div>
</template>

<script>
import axios from 'axios'
import fcmRehister from '@/views/AccountAdmin/components/fcmregister.vue'
import { getSessionStorage, getLocalStorage } from '@/utils/stage'
const IDreg =
  /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
export default {
  components: {
    fcmRehister,
  },
  data() {
    return {
      identity_no: '',
      real_name: '',
      token: '',
      tipsFlag: false,
      configData: '',
    }
  },
  created() {
    this.configData = JSON.parse(getSessionStorage('message'))
    this.getuserID()
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      const msg1 = {
        ErrorCode: 99,
      }
      window.vuplex.postMessage(msg1)
    },
    getuserID() {
      const that = this
      if (window.vuplex) {
        window.vuplex.addEventListener('message', function (message) {
          const messageInt1 = parseInt(JSON.parse(message.data).ErrorCode)
          console.log(6666, messageInt1, 'messageInt')
          if (messageInt1 === 99) {
            console.log(8888)
            const msg = JSON.parse(message.data).Msg
            that.real_name = msg.Name
            that.identity_no = msg.IdCard
            that.bindID()
            return
          }
        })
      }
    },
    closeTips(data) {
      if (data) {
        this.getSubmit()
      } else {
        this.getUserInfo()
      }
      this.tipsFlag = false
    },
    cancel() {
      alert('关闭通行证')
    },
    testID() {},
    IdCard(UUserCard) {
      //获取年龄
      let myDate = new Date()
      let month = myDate.getMonth() + 1
      let day = myDate.getDate()
      let age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1
      if (
        UUserCard.substring(10, 12) < month ||
        (UUserCard.substring(10, 12) == month &&
          UUserCard.substring(12, 14) <= day)
      ) {
        age++
      }
      return age
    },
    bindID() {
      // if (!this.real_name) {
      //   this.$toast(this.$t('toast.noName'))
      //   return
      // }
      // if (this.identity_no === '') {
      //   this.$toast($t('toast.noID'))
      //   return
      // } else if (!IDreg.test(this.identity_no)) {
      //   this.$toast($t('toast.verifyIDTips'))
      //   return
      // } else if (parseInt(this.IdCard(this.identity_no)) < 18) {
      //   this.tipsFlag = true
      // } else {
      //   this.getSubmit()
      // }
      if (parseInt(this.IdCard(this.identity_no)) < 18) {
        this.tipsFlag = true
      } else {
        this.getSubmit()
      }
    },
    getSubmit() {
      const access_token = JSON.parse(
        getLocalStorage('accountStorage')
      ).access_token
      const data = {
        identity_no: this.identity_no,
        real_name: this.real_name,
        access_token: access_token,
      }
      const msg = Object.assign(
        JSON.parse(JSON.stringify(this.configData)),
        data
      )
      const apiurl = process.env.VUE_APP_BASE_API
      axios.post(apiurl + '/identity/bind', msg).then((res) => {
        if (res.data.errcode != 0) {
          this.$toast(res.data.errmsg)
          setTimeout(() => {
            this.getUserInfo()
          }, 1000)
        } else {
          if (parseInt(this.IdCard(this.identity_no)) < 18) {
            this.$router.push({ path: '/childTips' })
          } else {
            this.$router.push({ path: '/success' })
          }
        }
      })
      // bindID(msg).then(res => {

      //   if (parseInt(this.IdCard(this.identity_no)) < 18) {
      //     this.$router.push({ path: '/childTips' })
      //   } else {
      //     this.$router.push({ path: '/success' })
      //   }
      // })
    },
  },
}
</script>

<style lang="scss" scoped>
.bindId_box {
  width: 100vw;
  height: 100vh;
  background: transparent;
  background-size: cover;
  position: relative;
}
// @media (min-width: 1600px) {
//   .bindId_box {
//     width: 100vw;
//     height: 100vh;
//     background:transparent;
//     // background: rgba(255, 255, 255, 0);
//     background-size: cover;
//     position: relative;
//   }
// }
// @media (min-width: 1200px) and (max-width:1599px) {
//   .bindId_box {
//     width: 100vw;
//     height: 100vh;
//     background:transparent;
//     // background: rgba(255, 255, 255, 0);
//     background-size: cover;
//     position: relative;
//   }
// }
// @media screen and (max-width:1199px) {
//   .bindId_box {
//     width: 100vw;
//     height: 100vh;
//     background:transparent;
//     // background: rgba(255, 255, 255, 0);
//     background-size: cover;
//     position: relative;
//   }
// }
</style>
